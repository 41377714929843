export default [
  {
    name: 'Home',
    path: '/',
    component: () => import('@/views/Home.vue'),
  },

  {
    name: 'PrintSamples',
    path: '/print-samples',
    component: () => import('@/views/PrintSamples.vue'),
  },
  {
    name: 'XsysTv',
    path: '/xsys-tv',
    component: () => import('@/views/XsysTv.vue'),
  },
  {
    name: 'Events',
    path: '/events',
    component: () => import('@/views/Events.vue'),
  },
  {
    name: 'Webinars',
    path: '/webinars',
    component: () => import('@/views/Webinars.vue'),
  },
  {
    name: 'TechnicalRoom',
    path: '/technical-room',
    component: () => import('@/views/TechnicalRoom.vue'),
  },
];
