import actions from './actions';
import mutations from './mutations';
import state from './state';

export default {
  namespace: true,
  actions,
  mutations,
  state,
};
