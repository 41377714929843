<template>
  <div data-vue-component-name="XsysTvContentList">
    <h6>Content:</h6>

    <div
      data-vue-component-name="XsysTvContentItem"
      v-for="(item, idx) of tvList"
      @click="onShowPopup(idx)"
      :key="idx"
    >
      <p class="title">{{ item.name }}</p>
      <p class="date">{{ item.date }}</p>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

defineProps({
  tvList: {
    type: Array,
    default: () => [],
  },
});

const onShowPopup = inject('onShowPopup');
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTvContentList"] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 32px 0;

  h6 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 32px;
  }

  [data-vue-component-name="XsysTvContentItem"] {
    @include spaceY(32px);

    .title {
      font-size: 18px;
      color: #fff;
      font-weight: 800;
      padding-bottom: 8px;
    }

    .date {
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>
