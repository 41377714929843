import { request, endpoints } from '@xsys2/functions';

export default {
  async getMenuItemsDescriptions({ commit }) {
    commit(
      'SET_MENU_ITEMS_DESCRIPTIONS',
      await request(endpoints('MAIN_MENU')),
    );
  },
  async getToken() {
    return request(endpoints('GET_TOKEN'));
  },
  async checkToken() {
    return request(endpoints('CHECK_TOKEN'));
  },
  async sendForm({ dispatch }, { subject, message }) {
    const token = await dispatch('getToken');

    return request(
      endpoints('SEND_MAIL'),
      JSON.stringify({ subject, message, token }),
    );
  },
};
