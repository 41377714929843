import { forEach, recursiveCamelCase } from '@xsys2/functions';

export default {
  SET_DATA(state, data) {
    forEach(recursiveCamelCase(data), (key, value) => {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = value;
      }
    });
  },
};
