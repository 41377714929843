<template>
  <div data-vue-component-name="XsysTvSingleView">
    <div class="player">
      <YoutubePreview
        :src="thumbnailUrl"
        @click="playVideo"
      />

      <YoutubePlayer
        v-if="isVideoPlaying"
        :video-id="videoId"
        autoplay
        ref="player"
        @ready="onReady"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { YoutubePreview, YoutubePlayer } from '@xsys2/components';
import {
  isAllKeysExist,
  findYoutubeVideoId,
  getYoutubeVideoThumbnailUrl,
} from '@xsys2/functions';

const props = defineProps({
  item: {
    type: Object,
    default: null,
    validator: item => isAllKeysExist(item, ['date', 'name', 'video']),
  },
});

const player = ref(null);
const isVideoPlaying = ref(false);
const videoId = ref(findYoutubeVideoId(props.item.video));
const thumbnailUrl = ref(getYoutubeVideoThumbnailUrl(videoId.value), 'maximum');
const onReady = () => player.value.playVideo();
const playVideo = () => (isVideoPlaying.value = true);
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTvSingleView"] {
  .player {
    position: relative;
    width: 311px;
    height: 175px;

    [data-vue-component-name="YoutubePreview"] {
      @include absolute-full;
    }

    [data-vue-component-name="YoutubePlayer"] {
      @include absolute-full(important);
      z-index: 2;
    }
  }
}

@media screen and (max-width: 768px) {
  [data-vue-component-name="XsysTvSingleView"] {
    .player {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
    }
  }
}
</style>
