import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createHead } from '@vueuse/head';
import directives from '@/directives';
import components from '@/components';
import '@xsys2/components/dist/components.css';
import './assets/styles/main.css';

createApp(App)
  .use(store)
  .use(router)
  .use(components)
  .use(directives)
  .use(createHead())
  .mount('#app');
