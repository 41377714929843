<template>
  <div data-vue-component-name="PrintSamplesSingleView">
    <ButtonClose @click="$emit('onClose')" />

    <h4>{{ card.title }}</h4>

    <div class="image-container">
      <AppImage
        :webp="card.imageWebp.url"
        :png="card.image.url"
        :alt="card.title"
      />
      <AppButton
        background="custom"
        backgroundColor="rgba(255, 255, 255, 0.2)"
        @click="downloadFile(card.downloadUrl)"
      >
        <IconDownloadCloud />
        <span>Download Flyer</span>
      </AppButton>

      <AppButton
        background="custom"
        backgroundColor="rgba(218, 41, 28, 0.5)"
        @click="toggleForm(true)"
      >
        <IconPackage />
        <span>Request Sample</span>
      </AppButton>
    </div>

    <PrintSamplesSingleViewParameters
      :description="card.description"
      :parameters="card.parameters"
    />

    <!--  form -->
    <transition name="fade">
      <AppWrapper
        v-if="isFormOpened"
        bg-color="rgba(0, 0, 0, 0.1)"
        blur="32"
        @click.self="toggleForm(false)"
      >
          <ButtonClose @click="toggleForm(false)" />
          <AppForm
            :title="requestSampleForm.formTitle"
            :description="requestSampleForm.formText"
            :inputs="requestSampleForm.fields"
            @on-submit="sendForm"
          />
      </AppWrapper>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  ButtonClose,
  AppImage,
  AppButton,
  IconDownloadCloud,
  IconPackage,
  AppWrapper,
  AppForm,
} from '@xsys2/components';
import { isAllKeysExist, downloadFile } from '@xsys2/functions';

const props = defineProps({
  card: {
    type: Object,
    default: null,
    validator: card => isAllKeysExist(
      card,
      [
        'description',
        'downloadUrl',
        'image',
        'imageWebp',
        'parameters',
        'segments',
        'title',
      ],
    ),
  },
});

const store = useStore();

const isFormOpened = ref(false);
const toggleForm = value => (isFormOpened.value = value);
const requestSampleForm = computed(() => store.state.printSamples.requestSampleForm);

const sendForm = async (values) => {
  const result = await store.dispatch(
    'sendForm',
    {
      subject: `Print Samples - ${props.card.title}`,
      message: values,
    },
  );

  if (result) {
    toggleForm(false);
  }
};

defineEmits(['onClose']);
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamplesSingleView"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding: 32px;
  overflow-x: auto;
  background-color: #000;

  & > * {
    @include spaceY(32px);
  }

  h4 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.1em;
  }

  .image-container {
    width: 100%;

    [data-vue-component-name="AppImage"] {
      position: relative;
      z-index: 0;
      object-fit: cover;
      height: auto;
      aspect-ratio: 1;
    }

    [data-vue-component-name="AppButton"] {
      width: 100%;
      padding: 25px 0;

      svg {
        margin-right: 16px;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  [data-vue-component-name="AppWrapper"] {
    @include fixed-full;
    z-index: 1;
    @include flex-center;
    flex-direction: column;

    [data-vue-component-name="AppForm"] {
      padding: 32px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 2rem 1rem;
      overflow-y: auto;
    }

    [data-vue-component-name="ButtonClose"] {
      margin-top: 6rem;
    }
  }
}
</style>
