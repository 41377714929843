<template>
  <div data-vue-component-name="WebinarsSingleView">
    <div class="player">
      <YoutubePreview
        :src="thumbnailUrl"
        @click="playVideo"
      />

      <YoutubePlayer
        v-if="isVideoPlaying"
        autoplay
        :video-id="videoId"
        ref="player"
        @ready="onReady"
      />
    </div>

    <div
      class="content"
      v-html="item.description"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { YoutubePreview, YoutubePlayer } from '@xsys2/components';
import {
  isAllKeysExist,
  findYoutubeVideoId,
  getYoutubeVideoThumbnailUrl,
} from '@xsys2/functions';

const props = defineProps({
  item: {
    type: Object,
    default: null,
    validator: value => isAllKeysExist(value, ['videoList', 'description']),
  },
});

const player = ref(null);
const isVideoPlaying = ref(false);
const playVideo = () => (isVideoPlaying.value = true);
const onReady = () => player.value.playVideo();

const videoId = ref(findYoutubeVideoId(props.item.videoList[0].video));
const thumbnailUrl = getYoutubeVideoThumbnailUrl(videoId.value, 'maximum');
</script>

<style scoped lang="scss">
[data-vue-component-name="WebinarsSingleView"] {
  .player {
    position: relative;
    width: 100%;
    padding-top: 62.5%;
    margin-bottom: 32px;

    [data-vue-component-name="YoutubePreview"] {
      @include absolute-full;
    }

    [data-vue-component-name="YoutubePlayer"] {
      @include absolute-full(important);
      z-index: 2;
    }
  }

  &::v-deep(.content) {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.75;
  }
}
</style>
