import { forEach } from '@xsys2/functions';

const foundModules = require.context(
  './',
  false,
  /\w+\.vue$/,
);

export default function (app) {
  forEach(foundModules.keys(), (index, path) => {
    const [, name] = path.match(/(\w+)\.vue$/);
    app.component(name, foundModules(path).default);
  });
}
