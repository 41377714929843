<template>
  <div data-vue-component-name="PrintSamplesMultipleView">
    <ButtonBack @click="onBack" />
    <h1>Print Samples</h1>
    <hr>

    <PrintSamplesNavigation
      :items="segments"
      :active-item-index="activeSegmentIndex"
      @update-index="toggleActiveSegmentIndex"
    />

    <PrintSamplesCards
      :cards="filteredPrintSamplesList"
      @on-click="card => $emit('onCardClick', card)"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ButtonBack, PrintSamplesNavigation } from '@xsys2/components';
import { isAllKeysExist } from '@xsys2/functions';

defineEmits({
  onCardClick: card => isAllKeysExist(
    card,
    [
      'description',
      'downloadUrl',
      'image',
      'imageWebp',
      'parameters',
      'segments',
      'title',
    ],
  ),
});

const store = useStore();
const router = useRouter();

const segments = computed(() => store.getters['printSamples/getSegments']);
const activeSegmentIndex = ref(0);
const toggleActiveSegmentIndex = index => (activeSegmentIndex.value = index);
const activeSegment = computed(() => segments.value[activeSegmentIndex.value]);

const printSamplesList = computed(() => store.state.printSamples.printSamplesList);
const filteredPrintSamplesList = computed(() => {
  const filteredList = printSamplesList.value
    .filter(card => card.segments.includes(activeSegment.value));

  return filteredList.length
    ? filteredList
    : printSamplesList.value;
});

const onBack = () => router.push('/');
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamplesMultipleView"] {
  padding: 32px 32px 0 32px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    @include spaceY(32px);
  }

  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.1em;
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: #fff;
    opacity: 0.1;
  }
}
</style>
