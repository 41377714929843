<template>
  <div data-vue-component-name="EventsMultipleView">
    <ButtonBack @click="onBack" />
    <h1>Events</h1>
    <hr>

    <transition name="fade" appear>
      <div
        v-if="eventsList.length"
        class="container"
      >
        <EventsCard
          v-for="(item, index) in eventsList"
          :key="index"
          :item="item"
          @click="onItemClick(item)"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ButtonBack } from '@xsys2/components';
import { isAllKeysExist } from '@xsys2/functions';

const emits = defineEmits({
  setActiveItem: item => isAllKeysExist(
    item,
    [
      'date',
      'eventSinglePage',
      'name',
      'status',
    ],
  ),
});

const store = useStore();
const router = useRouter();

const eventsList = computed(() => store.state.events.eventsList);
const onItemClick = item => emits('setActiveItem', item);

const onBack = () => router.push({ name: 'Home' });
</script>

<style scoped lang="scss">
[data-vue-component-name="EventsMultipleView"] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 32px 0 32px;

  & > * {
    @include spaceY(32px);
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.1em;
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: #fff;
    opacity: 0.1;
  }

  .container {
    position: relative;
    z-index: 0;
    height: 100%;
    overflow-y: scroll;

    [data-vue-component-name="EventsCard"] {
      @include spaceY(32px);
    }
  }
}
</style>
