<template>
  <div data-vue-component-name="TechnicalRoomProductsSlider">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="card"
      :class="{ active: activeIndex === index }"
      :ref="addRef"
      @click="$emit('updateActiveIndex', index)"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script setup>
import { watch, toRef, reactive } from 'vue';

const props = defineProps({
  activeIndex: {
    type: Number,
    default: 0,
  },
  items: {
    type: Array,
    default: () => [],
    validator: value => value
      .every(item => typeof item === 'string'),
  },
});

defineEmits({
  updateActiveIndex: value => typeof value === 'number',
});

const refs = reactive([]);
const addRef = newRef => refs.push(newRef);
const scrollTo = cardIndex => refs[cardIndex]?.scrollIntoView({
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
});

watch(
  toRef(props, 'activeIndex'),
  index => scrollTo(index),
  { immediate: true },
);
</script>

<style scoped lang="scss">
[data-vue-component-name="TechnicalRoomProductsSlider"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 120px;
  background: rgba(255, 255, 255, 0.1);
  overflow-y: hidden;
  overflow-x: auto;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  user-select: none;

  .card {
    padding: 41px 20px;
    cursor: pointer;
    min-width: 160px;
    width: 150px;

    span {
      display: block;
      font-weight: 600;
      opacity: 0.75;
      text-align: center;
      transition: font-weight 0.1s ease-in-out;
    }

    &.active {
      span {
        font-weight: 800;
        opacity: 1;
      }
    }
  }
}
</style>
