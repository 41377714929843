<template>
  <div data-vue-component-name="HomeListItem">
    <div
      class="cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/images/${img}`)}`,
      }"
      @click="goTo({ name: getRouterName(path) })"
    >
      <h2>{{ title }}</h2>
      <IconArrow />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { IconArrow } from '@xsys2/components';

defineProps({
  title: {
    type: String,
    default: null,
  },
  img: {
    type: String,
    default: null,
  },
  path: {
    type: String,
    default: null,
  },
});

const router = useRouter();

const getRouterName = path => router.resolve(path).name;
const goTo = route => router.push(route);
</script>

<style scoped lang="scss">
[data-vue-component-name="HomeListItem"] {
  position: relative;
  display: flex;
  flex-basis: 50%;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:first-child {
    flex-basis: 100%;
  }

  &:nth-child(2) {
    .cover {
      background-position: 50% 100%;
    }
  }

  .cover {
    @include absolute-full;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
      font-size: 22px;
      font-weight: 800;
      color: #fff;
    }

    [data-vue-component-name="IconArrow"] {
      width: 32px;
      height: 32px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #da291c;
      @include flex-center;
    }
  }
}
</style>
