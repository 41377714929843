<template>
  <div data-vue-component-name="XsysTvMultipleView">
    <hr />
    <span>Content:</span>

    <XsysTvList
      :items="tvList"
      @update:modelValue="onItemClick"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { XsysTvList } from '@xsys2/components';
import { isAllKeysExist } from '@xsys2/functions';

const emits = defineEmits({
  setActiveItem: item => isAllKeysExist(item, ['date', 'name', 'video']),
});

const store = useStore();

const tvList = computed(() => store.state.xsysTv.tvList);
const onItemClick = index => emits('setActiveItem', tvList.value[index]);
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTvMultipleView"] {
  hr {
    border: none;
    height: 1px;
    background-color: #fff;
    opacity: 0.1;
    margin-bottom: 32px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    opacity: 0.75;
    margin-bottom: 32px;
  }
}
</style>
