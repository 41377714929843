<template>
  <div data-vue-component-name="App">
    <TheHeader @onToggleMenu="toggleMenu(true)" />

    <TheMenu
      title="Virtual Sales"
      :items="menuItems"
      :active-item="activeMenuItem"
      :link="resolveLink"
      :class="{ opened: isMenuOpened }"
      @navigate="onNavigate"
    />

    <button
      :class="{ visible: isMenuOpened }"
      @click="toggleMenu(false)"
    >
      <IconClose />
    </button>

    <main>
      <router-view />
    </main>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { TheMenu, IconClose } from '@xsys2/components';

const store = useStore();
const router = useRouter();

store.dispatch('getMenuItemsDescriptions');
const isMenuOpened = ref(false);
const toggleMenu = payload => (isMenuOpened.value = payload);
const menuItems = computed(() => store.state.common.menuItems);
const activeMenuItem = computed(() => {
  const currentPath = router.currentRoute.value.fullPath;

  for (const key in menuItems.value) {
    if (menuItems.value[key]?.path === currentPath) {
      return menuItems.value[key];
    }
  }
});
const resolveLink = computed(() => process.env.VUE_APP_XSYS_GLOBAL_URL);
const onNavigate = async (item) => {
  const resolvedRoute = router.resolve(item.path);

  if (resolvedRoute.matched.length) {
    await router.push(resolvedRoute);
    isMenuOpened.value = false;
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="App"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  [data-vue-component-name="TheHeader"] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
  }

  [data-vue-component-name="TheMenu"] {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    overflow: hidden;

    &.opened {
      transform: translateX(0);
    }
  }

  button {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    [data-vue-component-name="IconClose"] {
      width: 24px;
      height: 24px;
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  main {
    margin-top: 56px;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 56px);
  }
}
</style>
