<template>
  <div data-vue-component-name="PrintSamplesPopupClose">
    <div
      class="close-btn"
      @click="onHidePopup"
    >
      <IconClose />
      <span>Close</span>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';
import { IconClose } from '@xsys2/components';

const onHidePopup = inject('onHidePopup');
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamplesPopupClose"] {
  margin: 32px 0;
  display: flex;
  justify-content: center;

  .close-btn {
    display: inline-flex;
    align-items: center;
    background: #494a4c;
    padding: 8px 15px;
    border-radius: 40px;
    min-height: 40px;

    span {
      font-size: 16px;
      color: #fff;
      margin-left: 14px;
    }
  }
}
</style>
