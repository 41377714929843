<template>
  <div data-vue-component-name="XsysTvContent">
    <XsysTvContentList :tvList="computedTvList" />

    <button
      v-if="shownItemsCount !== tvList.length"
      @click="showMore(tvList.length)"
    >
      Show more
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  tvList: {
    type: Array,
    default: () => [],
  },
});

const shownItemsCount = ref(3);
const showMore = payload => shownItemsCount.value = payload;

const computedTvList = computed(
  () => props.tvList.slice(0, shownItemsCount.value),
);
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTvContent"] {
  margin-bottom: 64px;

  button {
    color: #fff;
    font-size: 16px;
    padding: 0;
    margin-top: 32px;
  }
}

</style>
